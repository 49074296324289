import React, {FC, memo, ReactNode} from "react";
import {RiAiGenerate} from "react-icons/ri";
import {FaCat, FaFemale, FaGlobeAmericas, FaMale} from "react-icons/fa";
import {MdOutlineExplore, MdOutlineFemale, MdOutlineMale} from "react-icons/md";
import {BsInfoLg} from "react-icons/bs";

type IconsProps = {
    name: string
    className?: string
}

const icons: Record<string, ReactNode> = {
    ChineseNamesGenerator: <RiAiGenerate className="text-primary text-2xl" fill="currentColor"/>,
    ChineseMaleNamesGenerator: <FaMale className="text-sky-500 text-2xl" fill="currentColor"/>,
    ChineseFemaleNamesGenerator: (
        <FaFemale className="text-purple-500 text-2xl" fill="currentColor"/>
    ),
    ChineseGirlNamesGenerator: (
        <MdOutlineFemale className="text-pink-500 text-2xl" fill="currentColor"/>
    ),
    ChineseBoyNamesGenerator: (
        <MdOutlineMale className="text-blue-500 text-2xl" fill="currentColor"/>
    ),
    ChineseCatNamesGenerator: <FaCat className="text-black text-2xl" fill="currentColor"/>,
    explore: (
        <MdOutlineExplore
            className="text-teal-500 text-2xl"
            fill="currentColor"
        />
    ),
    info: <BsInfoLg className="text-gray-500 text-2xl" fill="currentColor"/>,
    ChineseNameGeneratorFromEnglish: (
        <FaGlobeAmericas className="text-blue-500 text-2xl" fill="currentColor"/>
    ),
    JapaneseNameGenerator: <span className="text-red-500 text-2xl">🇯🇵</span>,
    RandomAvatarGenerator: <span className="text-red-300 text-2xl">👤</span>,
};
export const Icons: FC<IconsProps> = memo(({name}) => {
    const key = name.replaceAll(" ", '')
    return <>
        {icons[key] ?? key}
    </>
})
Icons.displayName = "Icons"